import { accountRoute, lazyWithRetry } from '@hospy/shared-components';
import { useRoutes } from 'react-router-dom';

const PrivateLayout = lazyWithRetry(
	() => import('@admin/components/layouts/private-layouts')
);

const DashboardLayout = lazyWithRetry(
	() => import('@admin/components/layouts/dashboard-layout')
);

const AppRoutes = () => {
	const routes = useRoutes([
		...accountRoute
			.filter(({ path }) => path !== 'register')
			.map(({ path, component: Component }, index) => ({
				key: `account-route-${index}`,
				path,
				element: <Component />
			})),
		{
			element: <PrivateLayout />,
			children: [
				{
					path: '*',
					element: <DashboardLayout />
				}
			]
		}
	]);
	return routes;
};

export default AppRoutes;
